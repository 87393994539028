import { useEffect, useState } from "react";
import { Loader } from "../../../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { addCategory, addKnowledgeBank } from "../../../../redux/buyerSlice.ts";
import { RxCross1 } from "react-icons/rx";
import { toastNotification } from "../../../../services/notification-service.js";
import add from "../../../../assets/svg/add.svg";
import remove from "../../../../assets/svg/remove.svg";
import back from "./../../../../assets/svg/back.svg";
import pdf from "./../../../../assets/svg/pdf.svg";
import { Upload } from "../../../../components/upload/index.jsx";

export const AddFile = ({ editData, setEditData }) => {
	const [data, setData] = useState({});
	const dispatch = useDispatch();
	const [tag, setTag] = useState("");
	const { bank, isLoading, categories } = useSelector((store) => store?.buyer);

	useEffect(() => {
		if (editData?.id) {
			setData({
				cat: editData?.category_name,
				id: editData?.id,
				tags: editData?.tags,
				name: editData?.name,
				additional_votes: editData?.additional_votes,
				is_paid: editData?.is_paid,
				pdf_file: editData?.file_path?.path,
				thumbnail: editData?.image?.path,
			});
		} else {
			setData({});
		}
		const inputThumb = document.getElementById("dropzone-thumb");
		if (inputThumb) {
			inputThumb.value = "";
		}
		const inputpdf = document.getElementById("dropzone-pdf");
		if (inputpdf) {
			inputpdf.value = "";
		}
	}, [editData?.id]);

	const handleInputChange = (e) => {
		if (e.target.name === "tags") {
			if (e.target.value !== "") {
				setData({
					...data,
					[e.target.name]: [...(data?.[e.target.name] || []), e.target.value],
				});
			}
			return;
		}
		setData({ ...data, [e.target.name]: e.target.value });
	};

	const handleFileChange = async (e) => {
		const file = e.target.files[0];
		const inputThumb = document.getElementById(e.target.id);
		if (inputThumb) {
			inputThumb.value = "";
		}
		const maxSize =
			e.target.name === "thumbnail" ? 1 * 1024 * 1024 : 5 * 1024 * 1024; // 1MB for Thumbnail, 5MB for Pdf File

		if (file?.size <= maxSize) {
			setData({ ...data, [e.target.name]: file });
		} else {
			toastNotification({
				status: "error",
				message: `File size exceeds the limit. Maximum size allowed is ${(
					maxSize /
					(1024 * 1024)
				).toFixed(2)}MB.`,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if ((data?.tags || [])?.length === 0) {
			toastNotification({
				message: "Select Atleast 1 category",
				status: "warning",
			});
			return;
		}
		if (!data?.cat) {
			toastNotification({
				message: "Select Section",
				status: "warning",
			});
			return;
		}
		const formData = new FormData();
		let modifiedData = { ...data, tags: JSON.stringify(data?.tags) };
		if (data?.cat !== "other") {
			modifiedData["category"] = data?.cat;
		}
		if (typeof modifiedData?.pdf_file === "string") {
			delete modifiedData["pdf_file"];
		}
		if (typeof modifiedData?.thumbnail === "string") {
			delete modifiedData["thumbnail"];
		}
		Object.keys(modifiedData).forEach((key) => {
			formData.append(key, modifiedData[key]);
		});
		dispatch(
			addKnowledgeBank(
				{ formData: { formData } },
				setData,
				editData?.id,
				setEditData
			)
		);
	};

	const handleAddCategory = () => {
		if (tag) {
			dispatch(addCategory({ category: tag }));
			setTag("");
		}
	};

	const clearLocalStates = () => {
		setEditData({});
		setData({});
		const inputThumb = document.getElementById("dropzone-thumb");
		if (inputThumb) {
			inputThumb.value = "";
		}
		const inputpdf = document.getElementById("dropzone-pdf");
		if (inputpdf) {
			inputpdf.value = "";
		}
	};

	return (
		<div className="shadow-lg rounded-lg p-5 flex gap-y-4 flex-col justify-between items-start">
			{editData?.id && (
				<button
					onClick={() => clearLocalStates()}
					className="flex justify-start gap-x-2 text-orange-500 items-center"
				>
					<img src={back} className="w-6" /> Back
				</button>
			)}
			<form
				onSubmit={handleSubmit}
				className="w-full flex flex-col justify-start items-start "
			>
				<div className="flex justify-center items-center gap-x-8">
					<div className="relative z-20 min-w-56 group">
						<label className="block mb-2 text-sm font-light text-black">
							Name
						</label>
						<input
							name="name"
							onChange={handleInputChange}
							value={data?.name || ""}
							placeholder="Enter the file name"
							type="text"
							className="bg-transparent border border-orange-300 outline-none text-gray-900 text-sm rounded-lg focus:border-orange-600 block w-full p-2.5"
							required
						/>
					</div>
					{data?.cat !== "other" && (
						<div className="relative z-20 min-w-56 group">
							<label className="block mb-2 text-sm font-light text-black">
								Select Section
							</label>
							<select
								type="text"
								onChange={handleInputChange}
								value={data?.cat || ""}
								name="cat"
								className="bg-transparent border border-orange-300 text-gray-900 rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
								required
							>
								<option value={''}>Select</option>
								{Object?.keys(bank || {})
									?.filter(
										(cat) =>
											![
												"Educational Material",
												"Newsletter",
												"Whitepapers",
											]?.includes(cat)
									)
									.map((cat) => {
										return <option>{cat}</option>;
									})}
								<option>Educational Material</option>
								<option>Newsletter</option>
								<option>Whitepapers</option>
							</select>
						</div>
					)}
					{data?.cat === "other" && (
						<div className={`relative z-20 min-w-56 group`}>
							<label className="block mb-2 text-sm font-light text-black">
								Type New Section Name
							</label>
							<input
								name="category"
								onChange={handleInputChange}
								placeholder="Enter Section Name"
								type="text"
								className="bg-transparent border border-orange-300 outline-none text-gray-900 text-sm rounded-lg focus:border-orange-600 block w-full p-2.5"
								required
							/>
						</div>
					)}
					<button
						type="button"
						onClick={() => {
							if (data?.cat === "other") {
								setData({ ...data, cat: "" });
							} else {
								setData({ ...data, cat: "other" });
							}
						}}
						className="w-7 mt-6 -ml-7"
					>
						{data?.cat !== "other" ? (
							<img src={add} alt="add" />
						) : (
							<img src={remove} alt="remove" />
						)}
					</button>
					<div className="relative z-20 min-w-56 group">
						<label className="block mb-2 text-sm font-light text-black">
							Select Categories
						</label>
						<select
							name="tags"
							onChange={handleInputChange}
							value={"Select"}
							placeholder="Enter the file name"
							type="text"
							className="bg-transparent border border-orange-300 outline-none text-gray-900 text-sm rounded-lg focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>Select</option>
							{categories
								?.filter((t) => !(data?.tags || [])?.includes(t?.name))
								?.map((tag) => {
									return <option>{tag?.name}</option>;
								})}
						</select>
					</div>
					<div className="relative z-20 max-w-28 group">
						<label className="block mb-2 text-sm font-light text-black">
							Input Like Count
						</label>
						<input
							name="additional_votes"
							type="number"
							onChange={(e) =>
								Number(e.target.value) >= 0 && handleInputChange(e)
							}
							value={data?.additional_votes || ""}
							placeholder="000"
							className="bg-transparent border border-orange-300 outline-none text-gray-900 text-sm rounded-lg focus:border-orange-600 block w-full p-2.5"
							required
						/>
					</div>
					<div className="relative z-20 max-w-28 group">
						<label className="block mb-2 text-sm font-light text-black">
							Is Paid
						</label>
						<select
							name="is_paid"
							type="number"
							onChange={handleInputChange}
							value={data?.is_paid || false}
							placeholder="000"
							className="bg-transparent border border-orange-300 outline-none text-gray-900 text-sm rounded-lg focus:border-orange-600 block w-full p-2.5"
							required
						>
							<option value={null}></option>
							<option value={false}>No</option>
							<option value={true}>Yes</option>
						</select>
					</div>
				</div>

				<div className="flex justify-center items-center gap-x-8 mt-4">
					<div className="w-full">
						<label className="block mb-2 text-sm font-light text-black">
							Thumbnail
						</label>
						<Upload id={"thumbnail"}>
							<input
								id="thumbnail"
								type="file"
								accept=".jpeg, .jpg, .png, image/jpeg, image/jpg, image/png, image/gif"
								hidden="true"
								name="thumbnail"
								onChange={handleFileChange}
								max={1}
							/>
						</Upload>
					</div>
					<div className="w-full">
						<label className="block mb-2 text-sm font-light text-black">
							Pdf File
						</label>
						<Upload id={"pdf_file"}>
							<input
								id="pdf_file"
								type="file"
								accept="application/pdf"
								hidden="true"
								name="pdf_file"
								onChange={handleFileChange}
								max={1}
							/>
						</Upload>
					</div>
					<button className="mt-7 border-blue-600 text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out">
						{isLoading?.addBank ? (
							<Loader w="6" h="6" color={"blue"} />
						) : editData?.id ? (
							"Save"
						) : (
							"Add"
						)}
					</button>
				</div>
				<div className="flex justify-center items-end gap-x-10">
					{data?.thumbnail && (
						<img
							src={
								typeof data?.thumbnail === "string"
									? data?.thumbnail
									: URL.createObjectURL(data?.thumbnail)
							}
							className="w-32 mt-5 rounded-lg"
						/>
					)}
					{data?.pdf_file && (
						<a
							href={
								typeof data?.pdf_file === "string"
									? data?.pdf_file
									: URL.createObjectURL(data?.pdf_file)
							}
							target="_blank"
							rel="noopener noreferrer"
						>
							<img src={pdf} className="w-20 mt-5" alt="pdf" />
						</a>
					)}
				</div>
				{(data?.tags || [])?.length > 0 && (
					<>
						<label className="my-3 text-sm font-light text-black">
							Selected Categories
						</label>
						<div className="flex flex-wrap justify-start items-center gap-3">
							{data?.tags?.map((tag) => {
								return (
									<div className="flex items-center p-1 border border-gray-400 text-center rounded-lg">
										<p className="text-[10px] shrink-0">{tag}</p>
										<button
											type="button"
											onClick={() =>
												setData({
													...data,
													tags: data?.tags?.filter((t) => t !== tag),
												})
											}
											className="text-red-500 ml-2 shrink-0"
										>
											<RxCross1 size={12} color="red" />
										</button>
									</div>
								);
							})}
						</div>
					</>
				)}
			</form>
			<div className={`w-full bg-black h-[0.5px]`} />
			<div className="flex justify-center items-center gap-x-8">
				<div className={`relative z-20 min-w-56 group`}>
					<label className="block mb-2 text-sm font-light text-black">
						Type New Category Name
					</label>
					<input
						name="category"
						placeholder="Enter Category Name"
						value={tag || ""}
						onChange={(e) => setTag(e.target.value)}
						type="text"
						className="bg-transparent border border-orange-300 outline-none text-gray-900 text-sm rounded-lg focus:border-orange-600 block w-full p-2.5"
						required
					/>
				</div>
				<button
					type="button"
					onClick={() => handleAddCategory()}
					className="mt-7 border-orange-600 capitalize text-orange-600 hover:bg-orange-600 bg-white px-2 py-1.5 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
				>
					{isLoading?.addCategory ? (
						<Loader w={"5"} h={"5"} color={"orange"} />
					) : (
						"Add"
					)}
				</button>
			</div>
		</div>
	);
};
